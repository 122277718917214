import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';

const RegistrationPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [additionalData, setAdditionalData] = useState({
    weight: '',
    height: '',
    age: '',
    activity_level: 3,
    stress_level: 3,
  });
  const [registrationData, setRegistrationData] = useState({
    conditions: [],
    comment: '',
    goals: [],
    excluded_products: [],
    goals_comment: '',
    excluded_comment: '',
  });

  const navigate = useNavigate();

  const logMessage = (message, data) => {
    console.log(message, data);
  };

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();

    logMessage('Telegram WebApp готово.');

    const initData = tg.initDataUnsafe;

    if (initData) {
      const userData = {
        id: tg.initDataUnsafe.user.id,
        first_name: tg.initDataUnsafe.user.first_name,
        surname: tg.initDataUnsafe.user.surname || null, // Устанавливаем null, если фамилии нет
      };

      setUser(userData);
      logMessage('Данные пользователя получены:', userData);
      fetchUserData(userData.id);
    } else {
      logMessage('Ошибка получения данных пользователя.');
      toast.error('Ошибка получения данных пользователя.');
    }

    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    if (user && user.is_registered) {
      navigate('/profile');
    }
  }, [user, navigate]);

  const fetchUserData = async (userId) => {
    try {
      logMessage('Запрос данных пользователя с сервера...');
      const response = await axios.get(`https://postav.su/api/users/${userId}`);
      const userData = response.data;

      if (userData) {
        setUser(userData);
        setAdditionalData({
          weight: userData.weight || '',
          height: userData.height || '',
          age: userData.age || '',
          activity_level: userData.activity_level || 3,
          stress_level: userData.stress_level || 3,
        });
        setRegistrationData({
          conditions: userData.conditions ? userData.conditions.split(', ').filter(Boolean) : [],
          comment: userData.comment || '',
          goals: userData.goals ? userData.goals.split(', ').filter(Boolean) : [],
          excluded_products: userData.excluded_products ? userData.excluded_products.split(', ').filter(Boolean) : [],
          goals_comment: userData.goals_comment || '',
          excluded_comment: userData.excluded_comment || '',
        });
        logMessage('Данные пользователя получены с сервера:', userData);
      } else {
        logMessage('Данные пользователя не найдены на сервере.');
      }
    } catch (error) {
      logMessage('Ошибка при получении данных пользователя:', error);
    }
  };

  const handleAdditionalDataChange = (e) => {
    setAdditionalData({ ...additionalData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (formData) => {
    try {
      logMessage('Отправка данных пользователя на сервер...', formData);
      const response = await axios.post('https://postav.su/api/users', {
        ...formData,
        telegram_id: user.id, // Используем id из user, который является telegram_id
        is_registered: false, // Устанавливаем is_registered в false, пока пользователь не завершил регистрацию
      });
      logMessage('Данные пользователя успешно отправлены на сервер:', response.data);
      setCurrentStep(2); // Перенаправляем на следующий шаг после успешной отправки
    } catch (error) {
      logMessage('Ошибка при отправке данных пользователя:', error);
      toast.error('Ошибка при отправке данных пользователя.');
    }
  };

  const handleRegistrationDataChange = (key, value) => {
    setRegistrationData({ ...registrationData, [key]: value });
  };

  const handleConditionsChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      handleRegistrationDataChange('conditions', [...registrationData.conditions, value]);
    } else {
      handleRegistrationDataChange('conditions', registrationData.conditions.filter((condition) => condition !== value));
    }
  };

  const handleGoalsChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      handleRegistrationDataChange('goals', [...registrationData.goals, value]);
    } else {
      handleRegistrationDataChange('goals', registrationData.goals.filter((goal) => goal !== value));
    }
  };

  const handleExcludedProductsChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      handleRegistrationDataChange('excluded_products', [...registrationData.excluded_products, value]);
    } else {
      handleRegistrationDataChange('excluded_products', registrationData.excluded_products.filter((product) => product !== value));
    }
  };

  const submitRegistrationData = async () => {
    try {
      const dataToSend = {
        telegram_id: user.id, // Используем id из user, который является telegram_id
        conditions: registrationData.conditions.join(', '),
        comment: registrationData.comment,
        goals: registrationData.goals.join(', '),
        goals_comment: registrationData.goals_comment,
        excluded_products: registrationData.excluded_products.join(', '),
        excluded_comment: registrationData.excluded_comment,
        is_registered: true, // Устанавливаем is_registered в true после завершения регистрации
        ...additionalData, // Добавляем дополнительные данные
      };

      logMessage('Отправка регистрационных данных на сервер...', dataToSend);
      const response = await axios.post('https://postav.su/api/users', dataToSend);
      console.log('Регистрационные данные успешно отправлены:', response.data);
      toast.success('Регистрационные данные успешно отправлены!');

      // Обновляем состояние пользователя
      setUser({ ...user, is_registered: true });

      navigate('/profile'); // Перенаправляем на страницу профиля после успешной регистрации
    } catch (error) {
      console.error('Ошибка при отправке регистрационных данных:', error);
      toast.error('Ошибка при отправке регистрационных данных.');
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicDataForm
            user={user}
            additionalData={additionalData}
            handleAdditionalDataChange={handleAdditionalDataChange}
            handleSubmit={handleSubmit}
          />
        );
      case 2:
        return (
          <HealthForm
            handleConditionsChange={handleConditionsChange}
            handleRegistrationDataChange={handleRegistrationDataChange}
            registrationData={registrationData}
            setCurrentStep={setCurrentStep}
          />
        );
      case 3:
        return (
          <GoalsForm
            handleGoalsChange={handleGoalsChange}
            handleRegistrationDataChange={handleRegistrationDataChange}
            registrationData={registrationData}
            setCurrentStep={setCurrentStep}
          />
        );
      case 4:
        return (
          <ExcludedProductsForm
            handleExcludedProductsChange={handleExcludedProductsChange}
            handleRegistrationDataChange={handleRegistrationDataChange}
            registrationData={registrationData}
            submitRegistrationData={submitRegistrationData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <motion.div
      className="bg-gray-100 min-h-screen flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-4">Регистрация</h1>
        <div className="mb-4">
          <span className="text-gray-600">Шаг {currentStep} из 4</span>
          <div className="mt-2 bg-gray-200 rounded-full h-2">
            <div className="bg-blue-500 h-2 rounded-full" style={{ width: `${(currentStep / 4) * 100}%` }}></div>
          </div>
        </div>
        {loading ? (
          <p className="text-center">Загрузка...</p>
        ) : (
          renderStep()
        )}
      </div>
      <ToastContainer />
    </motion.div>
  );
};

const BasicDataForm = ({ user, additionalData, handleAdditionalDataChange, handleSubmit }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleAdditionalDataChange({ target: { name, value } });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit({ ...additionalData, first_name: user.first_name, surname: user.surname, telegram_id: user.id });
  };

  return (
    <form onSubmit={handleFormSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Рост (см)</label>
        <input
          type="number"
          name="height"
          value={additionalData.height}
          onChange={handleChange}
          className="mt-1 p-2 w-full border rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Вес (кг)</label>
        <input
          type="number"
          name="weight"
          value={additionalData.weight}
          onChange={handleChange}
          className="mt-1 p-2 w-full border rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Возраст</label>
        <input
          type="number"
          name="age"
          value={additionalData.age}
          onChange={handleChange}
          className="mt-1 p-2 w-full border rounded-md"
          required
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Уровень активности</label>
        <select
          name="activity_level"
          value={additionalData.activity_level}
          onChange={handleChange}
          className="mt-1 p-2 w-full border rounded-md"
        >
          <option value="1">Минимальная активность</option>
          <option value="2">Низкая активность</option>
          <option value="3">Средняя активность</option>
          <option value="4">Высокая активность</option>
          <option value="5">Очень высокая активность</option>
        </select>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Уровень стресса</label>
        <select
          name="stress_level"
          value={additionalData.stress_level}
          onChange={handleChange}
          className="mt-1 p-2 w-full border rounded-md"
        >
          <option value="1">Низкий</option>
          <option value="2">Средний</option>
          <option value="3">Высокий</option>
          <option value="4">Очень высокий</option>
        </select>
      </div>
      <button
        type="submit"
        className="bg-blue-500 text-white p-2 rounded-md w-full hover:bg-blue-600 mt-2"
      >
        Далее
      </button>
    </form>
  );
};

const HealthForm = ({ handleConditionsChange, handleRegistrationDataChange, registrationData, setCurrentStep }) => {
  return (
    <form className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Медицинские состояния:</label>
        <div className="mt-2 space-y-2">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="conditions"
              value="диабет"
              checked={registrationData.conditions.includes('диабет')}
              onChange={handleConditionsChange}
              className="mr-2"
            />
            Диабет
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="conditions"
              value="ожирение"
              checked={registrationData.conditions.includes('ожирение')}
              onChange={handleConditionsChange}
              className="mr-2"
            />
            Ожирение
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="conditions"
              value="гипертония"
              checked={registrationData.conditions.includes('гипертония')}
              onChange={handleConditionsChange}
              className="mr-2"
            />
            Гипертония
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="conditions"
              value="сердечные заболевания"
              checked={registrationData.conditions.includes('сердечные заболевания')}
              onChange={handleConditionsChange}
              className="mr-2"
            />
            Сердечные заболевания
          </label>
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Не нашли в списке?</label>
        <input
          type="text"
          name="comment"
          value={registrationData.comment}
          onChange={(e) => handleRegistrationDataChange('comment', e.target.value)}
          className="mt-1 p-2 w-full border rounded-md"
          maxLength="600"
        />
      </div>
      <button
        type="button"
        onClick={() => setCurrentStep(1)}
        className="bg-gray-500 text-white p-2 rounded-md w-full hover:bg-gray-600"
      >
        Назад
      </button>
      <button
        type="button"
        onClick={() => setCurrentStep(3)}
        className="bg-blue-500 text-white p-2 rounded-md w-full hover:bg-blue-600"
      >
        Далее
      </button>
    </form>
  );
};

const GoalsForm = ({ handleGoalsChange, handleRegistrationDataChange, registrationData, setCurrentStep }) => {
  return (
    <form className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Цели:</label>
        <div className="mt-2 space-y-2">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="goals"
              value="набор массы"
              checked={registrationData.goals.includes('набор массы')}
              onChange={handleGoalsChange}
              className="mr-2"
            />
            Набор массы
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="goals"
              value="снижение веса"
              checked={registrationData.goals.includes('снижение веса')}
              onChange={handleGoalsChange}
              className="mr-2"
            />
            Снижение веса
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="goals"
              value="поддержание текущего веса"
              checked={registrationData.goals.includes('поддержание текущего веса')}
              onChange={handleGoalsChange}
              className="mr-2"
            />
            Поддержание текущего веса
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="goals"
              value="улучшение здоровья"
              checked={registrationData.goals.includes('улучшение здоровья')}
              onChange={handleGoalsChange}
              className="mr-2"
            />
            Улучшение здоровья
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="goals"
              value="улучшение спортивных результатов"
              checked={registrationData.goals.includes('улучшение спортивных результатов')}
              onChange={handleGoalsChange}
              className="mr-2"
            />
            Улучшение спортивных результатов
          </label>
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Не нашли в списке?</label>
        <input
          type="text"
          name="goals_comment"
          value={registrationData.goals_comment}
          onChange={(e) => handleRegistrationDataChange('goals_comment', e.target.value)}
          className="mt-1 p-2 w-full border rounded-md"
          maxLength="600"
        />
      </div>
      <button
        type="button"
        onClick={() => setCurrentStep(2)}
        className="bg-gray-500 text-white p-2 rounded-md w-full hover:bg-gray-600"
      >
        Назад
      </button>
      <button
        type="button"
        onClick={() => setCurrentStep(4)}
        className="bg-blue-500 text-white p-2 rounded-md w-full hover:bg-blue-600"
      >
        Далее
      </button>
    </form>
  );
};

const ExcludedProductsForm = ({ handleExcludedProductsChange, handleRegistrationDataChange, registrationData, submitRegistrationData }) => {
  return (
    <form className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Исключенные продукты:</label>
        <div className="mt-2 space-y-2">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="мясо"
              checked={registrationData.excluded_products.includes('мясо')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Мясо
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="рыба"
              checked={registrationData.excluded_products.includes('рыба')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Рыба
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="молочное"
              checked={registrationData.excluded_products.includes('молочное')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Молочное
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="сладости"
              checked={registrationData.excluded_products.includes('сладости')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Сладости
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="фастфуд"
              checked={registrationData.excluded_products.includes('фастфуд')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Фастфуд
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="грибы"
              checked={registrationData.excluded_products.includes('грибы')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Грибы
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="фрукты"
              checked={registrationData.excluded_products.includes('фрукты')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Фрукты
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="овощи"
              checked={registrationData.excluded_products.includes('овощи')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Овощи
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="мучное"
              checked={registrationData.excluded_products.includes('мучное')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Мучное
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="крупы"
              checked={registrationData.excluded_products.includes('крупы')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Крупы
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              name="excluded_products"
              value="орехи"
              checked={registrationData.excluded_products.includes('орехи')}
              onChange={handleExcludedProductsChange}
              className="mr-2"
            />
            Орехи
          </label>
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Не нашли в списке?</label>
        <input
          type="text"
          name="excluded_comment"
          value={registrationData.excluded_comment}
          onChange={(e) => handleRegistrationDataChange('excluded_comment', e.target.value)}
          className="mt-1 p-2 w-full border rounded-md"
          maxLength="600"
        />
      </div>
      <button
        type="button"
        onClick={() => setCurrentStep(3)}
        className="bg-gray-500 text-white p-2 rounded-md w-full hover:bg-gray-600"
      >
        Назад
      </button>
      <button
        type="button"
        onClick={submitRegistrationData}
        className="bg-blue-500 text-white p-2 rounded-md w-full hover:bg-blue-600"
      >
        Завершить
      </button>
    </form>
  );
};

export default RegistrationPage;