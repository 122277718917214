import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Pie } from 'react-chartjs-2';
import { motion } from 'framer-motion';
import { User, Activity, Brain, Target, Apple, Heart, Camera } from 'lucide-react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import './App.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const [calories, setCalories] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const navigate = useNavigate();

  const fetchUserData = useCallback(async (userId) => {
    try {
      console.log('Fetching user data for userId:', userId);
      const response = await fetch(`https://postav.su/api/users/${userId}`);
      console.log('User data response:', response);
      const userData = await response.json();
      console.log('User data:', userData);
      
      if (userData) {
        setUser(userData);
      } else {
        toast.error('Данные пользователя не найдены.');
        navigate('/registration');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('Ошибка при получении данных пользователя.');
      navigate('/registration');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();

    const initData = tg.initDataUnsafe;
    if (initData?.user?.id) {
      fetchUserData(initData.user.id);
    } else {
      toast.error('Ошибка получения данных пользователя.');
      navigate('/registration');
    }
  }, [fetchUserData, navigate]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      console.log('Image selected:', file);
    }
  };

  const uploadImage = async () => {
    if (!image) {
      toast.error('Выберите изображение.');
      return;
    }
  
    const formData = new FormData();
    formData.append('image', image);
  
    try {
      console.log('Uploading image to server...');
      const response = await fetch('https://postav.su:5000/upload', {
        method: 'POST',
        body: formData,
      });
      console.log('Image upload response:', response);
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Image upload failed:', errorText);
        toast.error('Ошибка при загрузке изображения.');
        return;
      }
  
      const data = await response.json();
      console.log('Image analysis result:', data);
  
      // Выводим весь объект ответа
      setCalories(JSON.stringify(data, null, 2));
    } catch (error) {
      console.error('Error analyzing image:', error);
      toast.error('Ошибка при анализе изображения.');
    }
  };

  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 flex items-center justify-center">
        <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md text-center">
          <User className="w-16 h-16 mx-auto mb-4 text-gray-400" />
          <h2 className="text-xl font-semibold text-gray-800 mb-2">Профиль не найден</h2>
          <p className="text-gray-600">Данные пользователя недоступны</p>
        </div>
      </div>
    );
  }

  const chartOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 20,
          font: { 
            size: 12,
            family: 'Inter, system-ui, sans-serif'
          }
        }
      }
    },
    maintainAspectRatio: false,
    cutout: '70%'
  };

  const activityLevelData = {
    labels: [
      'Минимальная активность',
      'Низкая активность',
      'Средняя активность',
      'Высокая активность',
      'Очень высокая активность',
    ],
    datasets: [{
      data: [1, 1, 1, 1, 1].map((_, index) => (index === user.activity_level - 1 ? 1 : 0)),
      backgroundColor: [
        'rgba(234, 179, 8, 0.85)',
        'rgba(59, 130, 246, 0.85)',
        'rgba(16, 185, 129, 0.85)',
        'rgba(99, 102, 241, 0.85)',
        'rgba(236, 72, 153, 0.85)'
      ],
      borderWidth: 0
    }]
  };

  const stressLevelData = {
    labels: ['Низкий', 'Средний', 'Высокий', 'Очень высокий'],
    datasets: [{
      data: [1, 1, 1, 1].map((_, index) => (index === user.stress_level - 1 ? 1 : 0)),
      backgroundColor: [
        'rgba(16, 185, 129, 0.85)',
        'rgba(59, 130, 246, 0.85)',
        'rgba(245, 158, 11, 0.85)',
        'rgba(239, 68, 68, 0.85)'
      ],
      borderWidth: 0
    }]
  };

  const Card = ({ icon: Icon, title, children, className = '' }) => (
    <div className={`card ${className}`}>
      <div className="card-title">
        <Icon className="w-6 h-6" />
        <h2>{title}</h2>
      </div>
      {children}
    </div>
  );

  const InfoItem = ({ label, value }) => (
    <div className="info-item">
      <span className="label">{label}</span>
      <span className="value">{value}</span>
    </div>
  );

  const CommentBlock = ({ children }) => (
    <div className="comment-block">
      <p>{children}</p>
    </div>
  );

  return (
    <motion.div
      className="profile-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <div className="max-w-6xl mx-auto">
        <motion.div 
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.6 }}
          className="profile-header"
        >
          <h1>Профиль</h1>
          <p>Информация о пользователе и показатели здоровья</p>
        </motion.div>

        <div className="grid-container">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
          >
            <Card icon={User} title="Личная информация">
              <div className="space-y-4">
                <InfoItem label="Имя" value={user.name} />
                <InfoItem label="Фамилия" value={user.surname || 'Не указано'} />
                <InfoItem label="Рост" value={`${user.height} см`} />
                <InfoItem label="Вес" value={`${user.weight} кг`} />
                <InfoItem label="Возраст" value={user.age} />
              </div>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <Card icon={Activity} title="Уровень активности">
              <div className="chart-container">
                <Pie data={activityLevelData} options={chartOptions} />
              </div>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <Card icon={Brain} title="Уровень стресса">
              <div className="chart-container">
                <Pie data={stressLevelData} options={chartOptions} />
              </div>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <Card icon={Heart} title="Медицинские состояния">
              <div className="space-y-4">
                <p className="text-gray-700 leading-relaxed">
                  {user.conditions ? user.conditions.split(',').map((condition, index) => (
                    <span key={index}>{condition.trim()}<br/></span>
                  )) : 'Не указано'}
                </p>
                {user.comment && (
                  <CommentBlock>
                    {user.comment}
                  </CommentBlock>
                )}
              </div>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            <Card icon={Target} title="Цели">
              <div className="space-y-4">
                <p className="text-gray-700 leading-relaxed">
                  {user.goals ? user.goals.split(',').map((goal, index) => (
                    <span key={index}>{goal.trim()}<br/></span>
                  )) : 'Не указано'}
                </p>
                {user.goals_comment && (
                  <CommentBlock>
                    {user.goals_comment}
                  </CommentBlock>
                )}
              </div>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <Card icon={Apple} title="Исключенные продукты">
              <div className="space-y-4">
                <p className="text-gray-700 leading-relaxed">
                  {user.excluded_products ? user.excluded_products.split(',').map((product, index) => (
                    <span key={index}>{product.trim()}<br/></span>
                  )) : 'Не указано'}
                </p>
                {user.excluded_comment && (
                  <CommentBlock>
                    {user.excluded_comment}
                  </CommentBlock>
                )}
              </div>
            </Card>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.7 }}
          >
            <Card icon={Camera} title="Фотография пищи">
              <div className="space-y-4">
                <input type="file" accept="image/*" onChange={handleImageChange} />
                <button onClick={uploadImage} className="bg-blue-500 text-white px-4 py-2 rounded">Анализировать</button>
                {calories !== null && (
                  <div>
                    <p>Калории: {calories}</p>
                    {recommendations && (
                      <CommentBlock>
                        {recommendations}
                      </CommentBlock>
                    )}
                  </div>
                )}
              </div>
            </Card>
          </motion.div>
        </div>
      </div>
      <ToastContainer position="bottom-center" />
    </motion.div>
  );
};

export default ProfilePage;