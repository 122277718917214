import React from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import RegistrationPage from './RegistrationPage';
import ProfilePage from './ProfilePage';

const App = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-4xl">
        <h1 className="text-2xl font-bold mb-4">Приложение</h1>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/registration" />}
          />
          <Route
            path="/registration"
            element={<RegistrationPage />}
          />
          <Route
            path="/profile"
            element={<ProfilePage />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default App;